import styled from 'styled-components';

export const LoginContainer = styled.div`
  text-align: center;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 2rem;
  max-width: 450px;
  margin: 4rem auto 0 auto;

  h2 {
    margin: 0;
  }

  a {
    width: 100%;
  }
`;
