import Link from 'next/link';
import * as S from './TabNavigation.styled';
import { TabNavigationProps } from './TabNavigation.types';

export const TabNavigation: React.FC<TabNavigationProps> = ({
  links,
  variant = 'primary'
}) => {
  return (
    <S.Root variant={variant}>
      {links
        .filter(Boolean)
        .filter(({ isHidden }) => !isHidden)
        .map((link) => (
          <S.Link isActive={!!link.isActive} key={link.url}>
            <Link href={link.url}>
              <a>{link.label}</a>
            </Link>
          </S.Link>
        ))}
    </S.Root>
  );
};
