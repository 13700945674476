import {
  AdminPaginatedResource,
  AdminScoutTaskResponse,
  PaginatedResource
} from '@talent-engine/api-common';
import { getApiUrl } from '@utils/api';
import { httpClient } from '@utils/http';
import { getPaginationQuery } from '@utils/pagination';

import { ScoutTask } from '@models/scout-task';
import { normalizeToScoutTasks } from './service.scout-task.normalizer';
import { GetAllArgs } from './service.scout-task.types';

export const SCOUT_TASKS_PAGINATION_SIZE = 5;

export const ServiceScoutTask = {
  /**
   * Get the list of scout tasks
   *
   * @param args.includeCompleted whether if the completed tasks should be included
   * @param args.page the page number
   * @param args.pageSize the page size
   * @param args.opts the API options
   *
   * @returns the list of scout tasks
   */
  getAll: async (args: GetAllArgs): Promise<PaginatedResource<ScoutTask>> => {
    const {
      opts,
      pageSize = SCOUT_TASKS_PAGINATION_SIZE,
      page,
      includeCompleted = false
    } = args;

    const query = getPaginationQuery({
      page,
      pageSize
    });

    if (includeCompleted) {
      query.append('isCompleted', 'true');
    }

    const url = getApiUrl(`/admin/scouts/me/tasks?${query}`, opts?.useAppProxy);

    const res = await httpClient.get<
      AdminPaginatedResource<AdminScoutTaskResponse>
    >(url, {
      bearer: opts?.token
    });

    return normalizeToScoutTasks(res);
  }
};
