import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0rem;
`;

export const Link = styled.div<{
  index: number;
}>`
  > a {
    font-size: 0.875rem;
    text-decoration: none;
    padding: 0.15rem 0.5rem;

    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }

  &:last-child:not(:first-child) > a {
    color: #333;
  }
`;

export const Separator = styled.div`
  svg {
    color: #999;
    margin-bottom: -4px;
    height: 1.25rem;
  }
`;
