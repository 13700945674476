import { SSRConfig, i18n } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { DEFAULT_LOCALE } from './locale';

/**
 * Get the translations for the given namespaces on the given locale.
 * Namespaces are located under `public/locales/(locale)/*.json`.
 *
 * This method is intended to be used in every NextJS page, wether its SSR, SSG or ISR.
 *
 * @param namespaces namespaces to get the translations from
 * @param locale locale in which the translations should be returned
 *
 * @returns translations props ready to be passed to the page
 */
export async function getTranslations(
  namespaces: string[],
  locale?: string
): Promise<SSRConfig> {
  return serverSideTranslations(locale || DEFAULT_LOCALE, namespaces);
}

export function getTranslation(
  key: string,
  ns: string,
  locale?: string,
  opts?: Record<string, string | number>
): string {
  return i18n?.t(key, { ns, lng: locale, ...opts }) || '';
}
