import { Stack } from '@asgard/ui-library';
import { ScoutProcessesWidget } from './processes';
import { ScoutTasksWidget } from './tasks';

export const Dashboard: React.FC = () => {
  return (
    <Stack direction="column">
      <ScoutProcessesWidget />

      <ScoutTasksWidget />
    </Stack>
  );
};
