import { AppVersionResponse } from '@/pages/api';
import { Version } from '@models/model.version';
import { httpClient } from '@utils/http';

export const ServiceVersion = {
  get: async (): Promise<Version> => {
    const appVersion = await httpClient.get<AppVersionResponse>('/api');

    return {
      appVersion: `v${appVersion.metadata.build_version}`,
      apiVersion: `v${appVersion.apiVersion.metadata.build_version} (${appVersion.apiVersion.metadata.environment})`
    };
  }
};
