import { Header, Icon, Stack } from '@asgard/ui-library';
import { Page } from '@components/layout/Page';
import { faRobot, faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useI18n } from '@hooks/useI18n';
import { APP_PAGES, getPagePath } from '@utils/app-pages';
import { useRouter } from 'next/dist/client/router';
import { ErrorServerProps } from './ErrorServer.types';

// Component.
// -----------------------------------------------------------------------------
export const ErrorServer: React.FC<ErrorServerProps> = ({ stack }) => {
  // Hooks.
  // ---------------------------------------------------------------------------
  const router = useRouter();
  const { t } = useI18n('common');

  // Render.
  // ---------------------------------------------------------------------------
  return (
    <Page user={null}>
      <Stack alignment="center" direction="column">
        <Header as="h2" size="3xl">
          {t('common.page_500.title')}
        </Header>

        <Icon icon={faRobot} size="7xl" />

        <p>{t('common.page_500.desc')}</p>

        {stack && (
          <pre>
            <code>{stack}</code>
          </pre>
        )}

        <a href={getPagePath(APP_PAGES.logout, router.locale)}>
          <FontAwesomeIcon aria-hidden={true} icon={faUser} />
          <span>Accede de nuevo a la plataforma</span>
        </a>
      </Stack>
    </Page>
  );
};
