import styled, { css } from 'styled-components';

export const StyledMarkdown = styled.div<{ highlight?: boolean }>`
  word-break: break-word;

  p {
    margin-bottom: 1.5rem;
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul,
  ol {
    padding-left: 2rem !important;

    li {
      margin-bottom: 0.5rem;
      margin-top: 0 !important;
    }
  }

  ul {
    li {
      list-style-type: disc;
    }
  }

  ol {
    li {
      list-style-type: numeric;
    }
  }

  ${({ highlight }) =>
    highlight &&
    css`
      strong,
      em,
      code {
        display: inline-block;
        padding: 0 0.5rem;
        border-radius: 10px;
        font-style: normal;
        font-weight: normal;
        font-family: inherit;
      }

      strong {
        background-color: #ffffb8;
      }

      em {
        background-color: #b9f5b9;
      }

      code {
        background-color: #c3f2f6;
      }
    `}
`;
