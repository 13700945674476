import { Icon } from '@asgard/ui-library';
import { faSlashForward } from '@fortawesome/pro-light-svg-icons';
import Link from 'next/link';
import React from 'react';
import * as S from './Breadcrumbs.styled';
import { BreadcrumbsProps } from './Breadcrumbs.types';

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ links }) => {
  const MAX_LABEL_LENGTH = 75;

  return (
    <S.Root>
      {links.map((link, index) => (
        <React.Fragment key={link.url}>
          <S.Link key={link.url} index={index}>
            <Link href={link.url} passHref>
              <a>
                {link.label.length > MAX_LABEL_LENGTH
                  ? `${link.label.substring(0, MAX_LABEL_LENGTH)}...`
                  : link.label}
              </a>
            </Link>
          </S.Link>

          {index < links.length - 1 && (
            <S.Separator>
              <Icon icon={faSlashForward} />
            </S.Separator>
          )}
        </React.Fragment>
      ))}
    </S.Root>
  );
};
