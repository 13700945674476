import { z } from 'zod';

export const OfferTaskStatusValues = ['PENDING', 'COMPLETED'] as const;
export const OfferTaskAssignedToValues = ['MANFRED', 'COMPANY'] as const;
export const OfferTaskTypeValues = [
  'FEEDBACK',
  'FIRST_CONTACT',
  'OFFER_FINISHED',
  'OFFER_PAUSED',
  'OFFER_CANCELLED',
  'PUBLIC_OFFER_VALIDATED'
] as const;

export const OfferTaskSchema = z.object({
  id: z.number(),
  candidateId: z.number().optional(),
  candidateName: z.string().optional(),
  assignedTo: z.enum(OfferTaskAssignedToValues),
  createdAtAsIsoString: z.string(),
  finishedAtAsIsoString: z.string().optional(),
  type: z.enum(OfferTaskTypeValues),
  content: z.string().optional(),
  status: z.enum(OfferTaskStatusValues)
});

export type OfferTask = z.infer<typeof OfferTaskSchema>;
export type OfferTaskAssignedTo = typeof OfferTaskAssignedToValues[number];
export type OfferTaskType = typeof OfferTaskTypeValues[number];
export type OfferTaskStatus = typeof OfferTaskStatusValues[number];
