import { HttpError } from '@models/http-error/model.http-error';

export const getErrorMessage = (error: unknown): string =>
  error instanceof HttpError
    ? error.message
    : typeof (error as Error).message === 'object'
    ? JSON.stringify((error as Error).message)
    : (error as Error).message || (error as Error).toString();

export const getErrorName = (error: HttpError): string => error.name;
