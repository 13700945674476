import { Page } from '@components/layout/Page';
import { Dashboard } from './dashboard';
import { HomePageProps } from './HomePage.types';
import { HomeLogin } from './login';

export const HomePage: React.FC<HomePageProps> = ({ user }) => {
  return (
    <Page hideHeader={!user} user={user}>
      {user ? <Dashboard /> : <HomeLogin />}
    </Page>
  );
};
