import { Toast, UIProvider } from '@asgard/ui-library';
import { UserProvider } from '@auth0/nextjs-auth0/client';
import { ErrorBoundary } from '@components/shared/ErrorBoundary';
import { SegmentScript } from '@components/shared/SegmentScript';
import { GlobalStyles } from '@components/styles/GlobalStyles';
import { config as FAConfig, library } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import theme from '@theme/index';
import { ENV } from '@utils/env-vars';
import { faManfred } from '@utils/icons';
import { faAirtable } from '@utils/icons/airtable';
import { faPipedrive } from '@utils/icons/pipedrive';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { ThemeProvider } from 'styled-components';
import { SWRConfig } from 'swr';

// See: https://fontawesome.com/docs/web/use-with/react/use-with
FAConfig.autoAddCss = false;

library.add(faManfred);
library.add(faAirtable);
library.add(faPipedrive);

function App({ Component, pageProps }: AppProps): JSX.Element {
  const router = useRouter();

  return (
    <>
      <Toast position="bottom-right" />

      <Head>
        <title>Talent Engine Backoffice - Manfred</title>

        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />

        <meta property="og:locale" content={router.locale} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Manfred" />
      </Head>

      <SegmentScript />

      <ErrorBoundary>
        <ThemeProvider theme={theme}>
          <UIProvider theme={theme}>
            <SWRConfig
              value={{
                fallback: pageProps.fallback
              }}
            >
              <FlagsmithProvider
                flagsmith={flagsmith}
                {...(typeof window !== 'undefined' && {
                  options: {
                    environmentID: ENV.flagsmithEnvironmentKey
                  }
                })}
                serverState={{
                  ...pageProps.flagsmithState
                }}
              >
                <UserProvider>
                  <GlobalStyles />

                  <Component {...pageProps} />
                </UserProvider>
              </FlagsmithProvider>
            </SWRConfig>
          </UIProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </>
  );
}

export default appWithTranslation(App);
