import ReactMarkdown from 'react-markdown';
import { StyledMarkdown } from './MarkdownComponent.styled';
import { MarkdownComponentProps } from './MarkdownComponent.types';

const ALLOWED_ELEMENTS = [
  'p',
  'strong',
  'ul',
  'ol',
  'li',
  'em',
  'a',
  'br',
  'code'
];

export const MarkdownComponent: React.FC<MarkdownComponentProps> = ({
  content,
  highlight = false
}) => {
  return (
    <StyledMarkdown highlight={highlight}>
      <ReactMarkdown allowedElements={ALLOWED_ELEMENTS}>
        {content}
      </ReactMarkdown>
    </StyledMarkdown>
  );
};
