import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  td a {
    font-weight: bold;
  }

  th.offers-list-th-actions {
    width: 15%;
  }

  th.offers-list-th-process {
    width: 25%;
  }

  blockquote p:last-child {
    margin-bottom: 0;
  }

  h2 {
    margin: 0;
  }

  h2 svg {
    color: ${({ theme }) => theme.palette.text.subtle};
  }
`;

export const Desc = styled.blockquote`
  border-left: 4px solid #ccc;
  margin-left: 2rem;
  padding: 0.5rem;

  p {
    margin-top: 0;
  }
`;

export const Date = styled.span`
  color: #aaa;
  font-style: italic;
`;
