import { OfferTaskTypeValues } from '@models/offer-task';
import { OfferTaskStatusValues } from '@models/offer-task/model.offer-task';
import { z } from 'zod';

export const ScoutTaskSchema = z.object({
  companyId: z.number(),
  companyName: z.string(),
  processId: z.number(),
  processName: z.string(),

  id: z.number(),
  candidateId: z.number().optional(),
  candidateName: z.string().optional(),
  createdAtAsIsoString: z.string(),
  finishedAtAsIsoString: z.string().optional(),
  type: z.enum(OfferTaskTypeValues),
  content: z.string().optional(),
  status: z.enum(OfferTaskStatusValues)
});

export type ScoutTask = z.infer<typeof ScoutTaskSchema>;
