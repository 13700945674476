import { SalaryRange } from '@models/salary-range';
import { getTranslation } from 'utils/i18n';

export function getSalaryRangeText(
  { min, max, currency }: SalaryRange,
  minMax?: boolean,
  locale?: string
): string {
  if (min && !max) {
    return (
      getTranslation(
        minMax ? 'common:common.salary.min' : 'common:common.salary.from',
        'common',
        locale,
        {
          min: getKSalary(min),
          currency
        }
      ) || ''
    );
  }

  if (!min && max) {
    return getTranslation(
      minMax ? 'common:common.salary.max' : 'common:common.salary.up_to',
      'common',
      locale,
      {
        max: getKSalary(max),
        currency
      }
    );
  }

  if (min === max) {
    return getTranslation('common:common.salary.equal', 'common', locale, {
      min: getKSalary(min || 0),
      max: getKSalary(max || 0),
      currency
    });
  }

  return getTranslation('common:common.salary.minMax', 'common', locale, {
    min: getKSalary(min || 0),
    max: getKSalary(max || 0),
    currency
  });
}

/**
 * Get the salary in K format (without the letter K)
 *
 * @param salary - salary
 * @returns the salary converted to K format
 */
export function getKSalary(salary: number): number {
  return salary / 1000;
}
