import { getAccessToken } from '@auth0/nextjs-auth0';
import { HomePage, HomePageProps } from '@features/home';
import { USE_SCOUT_PROCESSES_KEY } from '@hooks/api/useScoutProcesses/useScoutProcesses.constants';
import { USE_SCOUT_TASKS_KEY } from '@hooks/api/useScoutTasks/useScoutTasks.constants';
import { ServiceScoutProcess } from '@services/scout-process';
import { SCOUT_PROCESSES_PAGINATION_SIZE } from '@services/scout-process/service.scout-process';
import { ServiceScoutTask } from '@services/scout-task';
import { SCOUT_TASKS_PAGINATION_SIZE } from '@services/scout-task/service.scout-task';
import { getTranslations } from '@utils/i18n';
import {
  PAGINATION_EMPTY_RESULT,
  PAGINATION_INITIAL_PAGE
} from '@utils/pagination';
import { createGetServerSideProps, getLoggedUser } from '@utils/ssr';

export default HomePage;

export const getServerSideProps = createGetServerSideProps<HomePageProps>(
  async function ({ locale, req, res }) {
    let processes;
    let tasks;

    const [translations, user] = await Promise.all([
      getTranslations(['common', 'home', 'offer-tasks'], locale),
      getLoggedUser(req, res)
    ]);

    if (user) {
      const { accessToken } = await getAccessToken(req, res);

      processes = await ServiceScoutProcess.getAll({
        page: PAGINATION_INITIAL_PAGE,
        pageSize: SCOUT_PROCESSES_PAGINATION_SIZE,
        opts: {
          useAppProxy: false,
          token: accessToken
        }
      });

      tasks = await ServiceScoutTask.getAll({
        page: PAGINATION_INITIAL_PAGE,
        pageSize: SCOUT_TASKS_PAGINATION_SIZE,
        opts: {
          useAppProxy: false,
          token: accessToken
        }
      });
    }

    return {
      props: {
        ...translations,
        user,
        fallback: {
          // This is a dirty hack to avoid NextJS "`undefined` cannot be serialized as JSON. Please use `null` or omit this value." error
          [`${USE_SCOUT_PROCESSES_KEY}/page/${PAGINATION_INITIAL_PAGE}/pageSize/${SCOUT_PROCESSES_PAGINATION_SIZE}/completed/false`]:
            processes
              ? JSON.parse(JSON.stringify(processes))
              : PAGINATION_EMPTY_RESULT,

          [`${USE_SCOUT_TASKS_KEY}/page/${PAGINATION_INITIAL_PAGE}/pageSize/${SCOUT_TASKS_PAGINATION_SIZE}/completed/false`]:
            processes
              ? JSON.parse(JSON.stringify(tasks))
              : PAGINATION_EMPTY_RESULT
        }
      }
    };
  }
);
