import {
  AdminOfferResponse,
  AdminOfferResponseTechLevelValues,
  AdminOfferResponseTechNecessityValues
} from '@talent-engine/api-common';
import { z } from 'zod';

export const OfferTechLevelValues = AdminOfferResponseTechLevelValues;
export const OfferTechNecessityValues = AdminOfferResponseTechNecessityValues;

export const OfferTechSchema = z.object({
  id: z.number(),
  name: z.string(),
  level: z.enum(OfferTechLevelValues),
  necessity: z.enum(OfferTechNecessityValues),
  iconURL: z.string().optional(),
  iconAlt: z.string().optional()
});

export type OfferTechLevel = AdminOfferResponse['techs'][0]['level'];
export type OfferTechNecessity = AdminOfferResponse['techs'][0]['necessity'];
export type OfferTech = z.infer<typeof OfferTechSchema>;
