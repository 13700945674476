import {
  Card,
  Checkbox,
  ControlledTable,
  Header,
  Icon,
  Stack
} from '@asgard/ui-library';
import { NoContent } from '@components/NoContent';
import { OfferStatus } from '@components/OfferStatus';
import { Pager } from '@components/Pager';
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { useScoutProcesses } from '@hooks/api/useScoutProcesses';
import { useI18n } from '@hooks/useI18n';
import { ScoutProcess } from '@models/scout-process';
import { SCOUT_PROCESSES_PAGINATION_SIZE } from '@services/scout-process/service.scout-process';
import { getCompanyPagePath, getOfferPagePath } from '@utils/app-pages';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import * as S from './ScoutProcessesWidget.styles';

export const ScoutProcessesWidget: React.FC = () => {
  const { t } = useI18n('home');
  const { locale } = useRouter();

  const [showCompleted, setShowCompleted] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState<ScoutProcess[]>([]);

  const { processes, isLoading } = useScoutProcesses({
    page: currentPage,
    includeCompleted: showCompleted
  });

  useEffect(() => {
    if (processes?.data) {
      setData(processes.data);
    }
  }, [processes?.data]);

  return (
    <S.Root>
      <Stack alignment="center">
        <Header as="h2" size="3xl">
          <Stack gap="m">
            <Icon icon={faMagnifyingGlass} size="xl" />
            {t('home.scout-dashboard.processes.title')}
          </Stack>
        </Header>

        <Checkbox
          label={t('home.scout-dashboard.processes.show-completed')}
          name="scoutProcesses_showCompleted"
          checked={showCompleted}
          onChange={() => {
            setShowCompleted((prev) => !prev);
            setCurrentPage(1);
          }}
        />
      </Stack>

      <Stack direction="column" gap="m">
        {!isLoading && data.length === 0 && (
          <Card hideImage>
            <NoContent text={t('home.scout-dashboard.processes.no-content')} />
          </Card>
        )}

        {data.length > 0 && (
          <ControlledTable<ScoutProcess>
            data={data}
            dataRowId="processId"
            zebra
            columns={[
              {
                type: 'badge',
                className: 'th-status',
                label: t('home.scout-dashboard.processes.headers.status'),
                sortable: true,
                sortValue: ({ status }) => status,
                render: ({ status }) => <OfferStatus status={status} />
              },

              {
                type: 'text',
                className: 'th-company',
                label: t('home.scout-dashboard.processes.headers.company'),
                sortable: true,
                sortValue: ({ companyName }) => companyName,
                render: ({ companyName, companyId }) => (
                  <Link
                    href={`${getCompanyPagePath(companyId, locale)}`}
                    passHref
                  >
                    <a>{companyName}</a>
                  </Link>
                )
              },

              {
                type: 'text',
                className: 'th-process',
                label: t('home.scout-dashboard.processes.headers.position'),
                sortable: true,
                sortValue: ({ processName }) => processName,
                render: ({ processName, companyId, processId }) => (
                  <Link
                    href={`${getOfferPagePath(processId, companyId, locale)}`}
                    passHref
                  >
                    <a>{processName}</a>
                  </Link>
                )
              }
            ]}
          />
        )}

        <Stack contentAlign="flex-end">
          <Pager
            page={currentPage}
            total={processes?.total || 0}
            pageSize={SCOUT_PROCESSES_PAGINATION_SIZE}
            onNext={() => {
              setCurrentPage((prev) => prev + 1);
            }}
            onPrev={() => {
              setCurrentPage((prev) => prev - 1);
            }}
          />
        </Stack>
      </Stack>
    </S.Root>
  );
};
