import { Scout } from '@models/scout';
import { ScoutSchema } from '@models/scout/model.scout';
import { AdminScoutResponse } from '@talent-engine/api-common';
import { Logger } from '@utils/log';

export function normalizeToScouts(res: AdminScoutResponse[]): Scout[] {
  return res.map(normalizeToScout);
}

export function normalizeToScout(res: AdminScoutResponse): Scout {
  const model: Scout = {
    id: res.id,
    email: res.email,
    pipedriveId: res.pipedriveId,
    companyId: res.companyId,
    name: res.name
  };

  const result = ScoutSchema.safeParse(model);

  if (!result.success) {
    Logger.error({
      context: 'normalizeToScout',
      message: `Could not parse scout with id: ${model.id}`,
      error: result.error
    });

    return model as Scout;
  }

  return result.data;
}
