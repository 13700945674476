import { ScoutProcess } from '@models/scout-process';
import {
  AdminPaginatedResource,
  AdminScoutProcessResponse,
  PaginatedResource
} from '@talent-engine/api-common';
import { getApiUrl } from '@utils/api';
import { httpClient } from '@utils/http';
import { getPaginationQuery } from '@utils/pagination';

import { normalizeToScoutProcesses } from './service.scout-process.normalizer';
import { GetAllArgs } from './service.scout-process.types';

export const SCOUT_PROCESSES_PAGINATION_SIZE = 10;

export const ServiceScoutProcess = {
  /**
   * Get the list of scout processes
   *
   * @param args.page the page number
   * @param args.pageSize the page size
   * @param opts the API options
   *
   * @returns the list of scout processes
   */
  getAll: async (
    args: GetAllArgs
  ): Promise<PaginatedResource<ScoutProcess>> => {
    const {
      opts,
      pageSize = SCOUT_PROCESSES_PAGINATION_SIZE,
      page,
      isCompleted = false
    } = args;
    const query = getPaginationQuery({
      page,
      pageSize
    });

    if (isCompleted) {
      query.append('isCompleted', 'true');
    }

    const url = getApiUrl(
      `/admin/scouts/me/processes?${query}`,
      opts?.useAppProxy
    );

    const res = await httpClient.get<
      AdminPaginatedResource<AdminScoutProcessResponse>
    >(url, {
      bearer: opts?.token
    });

    return normalizeToScoutProcesses(res);
  }
};
