import { z } from 'zod';

export const TaxonomyLocationSchema = z.object({
  placeId: z.string(),
  country: z.string().optional(),
  region: z.string().optional(),
  city: z.string().optional()
});

export type TaxonomyLocation = z.infer<typeof TaxonomyLocationSchema>;
