import { useQuery } from '@asgard/ui-common';
import { HttpError } from '@models/http-error/model.http-error';
import { PaginatedResource } from '@models/paginated-resource';
import { ScoutTask } from '@models/scout-task';
import { ServiceScoutTask } from '@services/scout-task';
import { SCOUT_TASKS_PAGINATION_SIZE } from '@services/scout-task/service.scout-task';
import { PAGINATION_INITIAL_PAGE } from '@utils/pagination';
import { USE_SCOUT_TASKS_KEY } from './useScoutTasks.constants';
import { UseScoutTasks } from './useScoutTasks.types';

export const useScoutTasks: UseScoutTasks = (args) => {
  const {
    page = PAGINATION_INITIAL_PAGE,
    pageSize = SCOUT_TASKS_PAGINATION_SIZE,
    includeCompleted = false
  } = args;

  const {
    data: tasks,
    isLoading,
    error
  } = useQuery<PaginatedResource<ScoutTask>, HttpError>(
    `${USE_SCOUT_TASKS_KEY}/page/${page}/pageSize/${pageSize}/completed/${includeCompleted}`,
    async () => {
      return ServiceScoutTask.getAll({ page, pageSize, includeCompleted });
    }
  );

  return {
    tasks,
    isLoading,
    error
  };
};
