import { OfferTask, OfferTaskSchema } from '@models/offer-task';
import { AdminTaskResponse } from '@talent-engine/api-common';
import { Logger } from '@utils/log';

export const normalizeToOfferTasks = (res: AdminTaskResponse[]): OfferTask[] =>
  res.map(normalizeToOfferTask);

export const normalizeToOfferTask = (res: AdminTaskResponse): OfferTask => {
  const task: OfferTask = {
    id: res.id,
    candidateId: res.candidate?.id,
    candidateName: res.candidate?.name,
    assignedTo: res.assignedToRole,
    createdAtAsIsoString: new Date(res.createdAt).toISOString(),
    finishedAtAsIsoString: res.finishedAt
      ? new Date(res.finishedAt).toISOString()
      : undefined,
    type: res.label || 'FEEDBACK',
    content: res.content,
    status: res.status
  };

  const result = OfferTaskSchema.safeParse(task);

  if (!result.success) {
    Logger.error({
      context: 'normalizeToOfferTask',
      message: `Error parsing OfferTask with id: ${res.id}`,
      error: result.error
    });

    return task;
  }

  return result.data;
};
