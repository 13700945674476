import { Button, Modal, Stack } from '@asgard/ui-library';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { useI18n } from '@hooks/useI18n';
import { useCallback, useState } from 'react';
import { ConfirmModalProps } from './ConfirmModal.types';

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  cancelText,
  confirmText,
  isOpen,
  title,
  desc,
  onClose,
  onConfirm
}) => {
  const { t } = useI18n('common');
  const [isConfirming, setIsConfirming] = useState(false);

  const handleOnConfirm = useCallback(async () => {
    try {
      setIsConfirming(true);
      await onConfirm();
    } finally {
      setIsConfirming(false);
    }
  }, [onConfirm]);

  return (
    <Modal isOpen={isOpen} title={title} closeable={false}>
      <Stack direction="column">
        <div>{desc}</div>

        <Stack contentAlign="flex-end">
          <Button
            variant="ghost"
            label={cancelText || t('common.confirm.cancel')}
            onClick={onClose}
            startIcon={faTimesCircle}
          />

          <Button
            label={confirmText || t('common.confirm.ok')}
            onClick={handleOnConfirm}
            isLoading={isConfirming}
            startIcon={faCheckCircle}
          />
        </Stack>
      </Stack>
    </Modal>
  );
};
