import { OfferStatus } from '@components/OfferStatus';
import {
  faBadgeCheck,
  faComputer,
  faMapMarkerAlt,
  faMoneyBills
} from '@fortawesome/pro-solid-svg-icons';
import { ListItemStringValue } from '@models/list-item';
import { OfferDetail } from '@models/offer';
import { OfferFeat } from '@models/offer-feat';
import { i18n } from 'next-i18next';
import { ReactNode } from 'react';
import { getSalaryRangeText } from './salary';

export function isOfferFeat(feat: OfferFeat | unknown): feat is OfferFeat {
  return (feat as OfferFeat).label !== undefined;
}

export function getOfferFeats({
  offer,
  techs,
  roles
}: {
  offer: OfferDetail;
  techs: ListItemStringValue[];
  roles: ListItemStringValue[];
}): Array<OfferFeat | ReactNode> {
  const feats: Array<OfferFeat | ReactNode> = [];

  feats.push(<OfferStatus status={offer.status} />);

  if (offer.salary?.min && offer.salary?.max) {
    feats.push({
      icon: faMoneyBills,
      label: getSalaryRangeText({
        id: 0,
        currency: '€',
        max: offer.salary.max,
        min: offer.salary.min
      })
    });
  }

  if (offer.rolesIds?.length > 0) {
    feats.push({
      icon: faBadgeCheck,
      label: roles
        .filter(({ value }) => offer.rolesIds.includes(+value))
        .map(({ label }) => label)
        .join(', ')
    });
  }

  if (offer.techs?.length > 0) {
    const mustTechs = offer.techs
      .filter(({ necessity }) => necessity === 'MUST')
      .map(({ id }) => id);

    if (mustTechs && mustTechs.length > 0) {
      feats.push({
        icon: faComputer,
        label: techs
          .filter(({ value }) => mustTechs.includes(+value))
          .map(({ label }) => label)
          .join(', ')
      });
    }
  }

  feats.push({
    icon: faMapMarkerAlt,
    label: `${i18n?.t(`common:common.presenceMode.${offer.presenceMode}`)}${
      !!offer.locations && offer.locations?.length > 0
        ? ` – ${offer.locations
            .map(({ city }) => city)
            .filter(Boolean)
            .join(', ')}`
        : ''
    }`
  });

  return feats;
}
