import { PaginatedResource } from '@models/paginated-resource';
import { ScoutTask, ScoutTaskSchema } from '@models/scout-task';
import {
  AdminPaginatedResource,
  AdminScoutTaskResponse
} from '@talent-engine/api-common';
import { Logger } from '@utils/log';

export function normalizeToScoutTasks(
  res: AdminPaginatedResource<AdminScoutTaskResponse>
): PaginatedResource<ScoutTask> {
  return {
    data: res.data.map(normalizeToScoutTask),
    page: res.page,
    size: res.size,
    total: res.total
  };
}

export function normalizeToScoutTask(res: AdminScoutTaskResponse): ScoutTask {
  const model: ScoutTask = {
    companyId: res.companyId,
    processId: res.processId,
    companyName: res.companyName,
    processName: res.processName,

    id: res.id,
    candidateId: res.candidate?.id,
    candidateName: res.candidate?.name,
    createdAtAsIsoString: new Date(res.createdAt).toISOString(),
    finishedAtAsIsoString: res.finishedAt
      ? new Date(res.finishedAt).toISOString()
      : undefined,
    type: res.label || 'FEEDBACK',
    content: res.content,
    status: res.status
  };

  const result = ScoutTaskSchema.safeParse(model);

  if (!result.success) {
    Logger.error({
      context: 'normalizeToScoutTask',
      message: `Could not parse scout process with process id: ${model.processId} and company id: ${model.companyId}`,
      error: result.error
    });

    return model;
  }

  return result.data;
}
