import { IconDefinition, IconName } from '@fortawesome/fontawesome-svg-core';

export const faAirtable: IconDefinition = {
  // Use a prefix like 'fac' that doesn't conflict with a prefix in the standard Font Awesome styles
  // (So avoid fab, fal, fas, far, fa)
  prefix: 'fas',
  iconName: 'airtable' as IconName, // Any name you like
  icon: [
    32, // width
    32, // height
    [], // ligatures
    '', // unicode (if relevant)
    'M1.849 11.12c-0.008-0-0.018-0.001-0.029-0.001-0.223 0-0.425 0.091-0.571 0.238l-0 0c-0.141 0.126-0.234 0.304-0.245 0.504l-0 0.002v10.441c0.024 0.42 0.371 0.751 0.794 0.751 0.124 0 0.241-0.028 0.345-0.079l-0.005 0.002 8.219-3.94 3.71-1.794c0.246-0.125 0.411-0.376 0.411-0.666 0-0.319-0.2-0.591-0.482-0.697l-0.005-0.002-11.884-4.706c-0.076-0.033-0.165-0.053-0.258-0.055l-0.001-0zM30.246 11.071c-0.1 0.001-0.195 0.021-0.282 0.058l0.005-0.002-12.511 4.845c-0.28 0.117-0.474 0.388-0.475 0.705v11.117c0.004 0.411 0.338 0.743 0.75 0.743 0.099 0 0.194-0.019 0.281-0.055l-0.005 0.002 12.513-4.861c0.28-0.106 0.475-0.372 0.475-0.683 0-0.002 0-0.004-0-0.006v0-11.117c-0.003-0.412-0.337-0.745-0.75-0.745 0 0 0 0-0 0v0zM15.99 3.461c-0.577 0-1.127 0.118-1.627 0.331l0.027-0.010-11.163 4.616c-0.274 0.116-0.463 0.383-0.463 0.694 0 0.317 0.196 0.588 0.473 0.699l0.005 0.002 11.224 4.446c0.454 0.189 0.981 0.299 1.533 0.299s1.080-0.11 1.56-0.309l-0.027 0.010 11.224-4.446c0.28-0.115 0.473-0.385 0.473-0.7 0-0.31-0.187-0.576-0.453-0.692l-0.005-0.002-11.193-4.616c-0.468-0.203-1.012-0.321-1.584-0.321-0.002 0-0.004 0-0.006 0h0z' // svg path data
  ]
};
