import { ButtonLink, Header, Stack } from '@asgard/ui-library';
import { faSignIn } from '@fortawesome/pro-solid-svg-icons';
import { useI18n } from '@hooks/useI18n';
import { APP_PAGES, getPagePath } from '@utils/app-pages';
import { useRouter } from 'next/router';
import * as S from './HomeLogin.styled';

export const HomeLogin: React.FC = () => {
  const { t } = useI18n('common');
  const { locale } = useRouter();

  return (
    <S.LoginContainer>
      <Stack direction="column" gap="3xl" alignment="center">
        <img
          src="/images/talent-engine-backoffice.svg"
          alt={t('common.page-title')}
          width="175px"
        />

        <Stack direction="column" gap="m" alignment="center">
          <Header as="h2" size="xl">
            {t('home:home.welcome_title')}
          </Header>

          <div>{t('home:home.welcome_text')}</div>

          <hr />

          <ButtonLink
            label={t('common.login')}
            schema="brand2"
            variant="hard"
            size="xs"
            href={getPagePath(APP_PAGES.login, locale)}
            endIcon={faSignIn}
          />
        </Stack>
      </Stack>
    </S.LoginContainer>
  );
};
