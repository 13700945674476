import { OfferStatusValues } from '@models/offer';
import { z } from 'zod';

export const ScoutProcessSchema = z.object({
  companyId: z.number(),
  companyName: z.string(),
  processId: z.number(),
  processName: z.string(),
  status: z.enum(OfferStatusValues)
});

export type ScoutProcess = z.infer<typeof ScoutProcessSchema>;
