import { CompanyUserSchema } from '@models/company-user';
import {
  LONG_TEXT_MAX_LENGTH,
  MID_TEXT_MAX_LENGTH
} from '@models/model.constants';
import { MediaSchema } from '@models/model.media';
import { OfferTechSchema } from '@models/offer-tech';
import { TaxonomyLocationSchema } from '@models/taxonomy-location/model.taxonomy-location';
import { z } from 'zod';
import { LanguageWithLevelSchema } from '../model.language-with-level';
import { SalarySchema } from '../model.salary';

export const OfferStatusValues = [
  'ACTIVE',
  'PENDING',
  'FINISHED',
  'CANCELLED',
  'SEARCHING_CANDIDATES',
  'PAUSED'
] as const;
export const OfferPresenceModeValues = [
  'REMOTE',
  'HYBRID',
  'FACE-TO-FACE'
] as const;
export const OfferPipelineTypeValues = ['AGENCY', 'TALENT_ENGINE'] as const;

export const OfferAllowedNextStatusesValues = [
  'PENDING',
  'SEARCHING_CANDIDATES',
  'ACTIVE',
  'PAUSED',
  'FINISHED',
  'CANCELLED'
] as const;

export const OfferResumeSchema = z.object({
  id: z.number(),
  name: z.string(),
  status: z.enum(OfferStatusValues),
  creationDateString: z.string(),
  finishDateString: z.string().optional().nullable(),
  pipedriveId: z.string().optional(),
  candidatesCount: z.number(),
  scoutId: z.number()
});

export const OfferDetailSchema = z
  .object({
    vacancies: z.number().optional(),
    experience: z.number().optional(),
    salary: SalarySchema.optional(),
    process: z.string().optional(),
    locationComments: z.string().optional(),
    inOneMonth: z.string().optional(),
    inThreeMonths: z.string().optional(),
    inSixMonths: z.string().optional(),
    rolesIds: z.array(z.number()),
    techs: z.array(OfferTechSchema),
    practicesKnowledgeIds: z.array(z.number()),
    experienceTypeIds: z.array(z.number()),
    sectorIds: z.array(z.number()),
    softSkillsIds: z.array(z.number()),
    responsibilities: z.string().optional(),
    jobDescription: z.string().optional(),
    presenceMode: z.enum(OfferPresenceModeValues),
    locations: z.array(TaxonomyLocationSchema),
    languages: z.array(LanguageWithLevelSchema),
    whoWillDoItWith: z.string().optional(),
    buddy: CompanyUserSchema.optional(),
    notifiers: z.array(CompanyUserSchema),
    isManuallyUnlocked: z.boolean(),
    descriptionFile: MediaSchema.optional(),
    hasPerformanceReview: z.boolean(),
    performanceReviewComments: z.string().max(MID_TEXT_MAX_LENGTH).optional(),
    hasGuards: z.boolean(),
    guardsComments: z.string().max(MID_TEXT_MAX_LENGTH).optional(),
    hasAcademicNeeds: z.boolean(),
    academicNeedsComments: z.string().max(MID_TEXT_MAX_LENGTH).optional(),

    links: z.array(z.string()),

    isTransversalProfile: z.boolean(),
    transversalProfileComments: z.string().max(MID_TEXT_MAX_LENGTH).optional(),

    otherInfo: z.string().max(LONG_TEXT_MAX_LENGTH).optional(),
    challenge: z.string().max(LONG_TEXT_MAX_LENGTH).optional(),
    teamMethodology: z.string().max(LONG_TEXT_MAX_LENGTH).optional(),
    teamCommunication: z.string().max(LONG_TEXT_MAX_LENGTH).optional(),
    teamHappiness: z.string().max(LONG_TEXT_MAX_LENGTH).optional(),
    pipelineType: z.enum(OfferPipelineTypeValues),
    hideAnonymousKanbanView: z.boolean(),
    allowedNextStatuses: z.array(z.enum(OfferAllowedNextStatusesValues))
  })
  .and(OfferResumeSchema);

export type OfferResume = z.infer<typeof OfferResumeSchema>;
export type OfferDetail = z.infer<typeof OfferDetailSchema>;
export type OfferStatus = typeof OfferStatusValues[number];
export type OfferPresenceMode = typeof OfferPresenceModeValues[number];
export type OfferPipelineType = typeof OfferPipelineTypeValues[number];
export type OfferAllowedNextStatus =
  typeof OfferAllowedNextStatusesValues[number];
