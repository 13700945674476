import { useQuery } from '@asgard/ui-common';
import { HttpError } from '@models/http-error/model.http-error';
import { PaginatedResource } from '@models/paginated-resource';
import { ScoutProcess } from '@models/scout-process';
import { ServiceScoutProcess } from '@services/scout-process';
import { SCOUT_PROCESSES_PAGINATION_SIZE } from '@services/scout-process/service.scout-process';
import { PAGINATION_INITIAL_PAGE } from '@utils/pagination';
import { USE_SCOUT_PROCESSES_KEY } from './useScoutProcesses.constants';
import { UseScoutProcesses } from './useScoutProcesses.types';

export const useScoutProcesses: UseScoutProcesses = (args) => {
  const {
    page = PAGINATION_INITIAL_PAGE,
    pageSize = SCOUT_PROCESSES_PAGINATION_SIZE,
    includeCompleted = false
  } = args;

  const {
    data: processes,
    isLoading,
    error
  } = useQuery<PaginatedResource<ScoutProcess>, HttpError>(
    `${USE_SCOUT_PROCESSES_KEY}/page/${page}/pageSize/${pageSize}/completed/${includeCompleted}`,
    async () => {
      return ServiceScoutProcess.getAll({
        page,
        pageSize,
        isCompleted: includeCompleted
      });
    }
  );

  return {
    processes,
    isLoading,
    error
  };
};
