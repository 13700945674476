import styled, { css } from 'styled-components';
import { TabNavigationProps } from './TabNavigation.types';

export const Root = styled.div<Pick<TabNavigationProps, 'variant'>>`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.75rem;
  gap: 2rem;
  border-bottom: 4px solid rgb(228, 228, 228);
  padding-bottom: 0.5rem;
  font-size: 1rem;

  > a {
    border-bottom-width: 4px;
  }

  ${({ variant }) =>
    variant === 'secondary' &&
    css`
      border-color: transparent;
      font-size: 1rem;
      border-bottom-width: 4px;
      gap: 0.25rem;

      ${Link} > a {
        border-bottom-width: 4px;
        color: #000;
        text-transform: capitalize;
        padding: 0.75rem 1.5rem;
      }
    `}
`;

export const Link = styled.div<{ isActive: boolean }>`
  > a {
    color: #333;
    text-transform: uppercase;
    font-weight: 700;
    padding: 0.5rem;
    text-decoration: none;
    border-bottom: 3px solid transparent;
    transition: border-color 0.2s, font-weight 0.2s;

    &:focus,
    &:hover {
      border-color: ${({ theme }) =>
        theme.palette.schema.neutral.light.soft.default.background};
    }

    ${({ isActive }) =>
      isActive &&
      css`
        &,
        &:focus,
        &:hover {
          font-weight: 800;
          border-color: #00ccaa !important;
        }
      `}
  }
`;
