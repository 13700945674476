import { Button, Stack } from '@asgard/ui-library';
import { useI18n } from '@hooks/useI18n';
import { PagerProps } from './Pager.types';

export const Pager: React.FC<PagerProps> = ({
  page,
  pageSize,
  total,
  onNext,
  onPrev
}) => {
  const { t } = useI18n('common');

  if (total <= pageSize) {
    return null;
  }

  const totalPages = Math.ceil(total / pageSize);

  return (
    <Stack alignment="center">
      {page > 1 && (
        <Button label="←" variant="ghost" size="2xs" onClick={onPrev} />
      )}

      <span>
        {t('common.pager.page', {
          page,
          total: totalPages
        })}
      </span>

      {page < totalPages && (
        <Button label="→" variant="ghost" size="2xs" onClick={onNext} />
      )}
    </Stack>
  );
};
