export {
  OfferTechSchema,
  OfferTechLevelValues,
  OfferTechNecessityValues
} from './model.offer-tech';
export type {
  OfferTech,
  OfferTechLevel,
  OfferTechNecessity
} from './model.offer-tech';
