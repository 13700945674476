module.exports = {
  home: {
    page: '/index',
    langs: {
      es: '/'
    },
    params: ''
  },

  companies: {
    page: '/companies',
    langs: {
      es: '/clientes'
    },
    params: ''
  },

  manfrediteSearch: {
    page: '/search',
    langs: {
      es: '/manfreditas'
    },
    params: ''
  },

  manfrediteDetail: {
    page: '/manfredites/:manfrediteId',
    langs: {
      es: '/manfreditas/:manfrediteId'
    },
    params: ''
  },

  taxonomyTechs: {
    page: '/taxonomyTechs',
    langs: {
      es: '/taxonomy/techs'
    },
    params: ''
  },

  taxonomyTechsAdd: {
    page: '/taxonomyTechs/add',
    langs: {
      es: '/taxonomy/techs/create'
    },
    params: ''
  },

  taxonomyTechsEdit: {
    page: '/taxonomyTechs/edit/:techId',
    langs: {
      es: '/taxonomy/techs/edit/:techId'
    },
    params: ''
  },

  scouts: {
    page: '/scouts',
    langs: {
      es: '/scouts'
    },
    params: ''
  },

  scoutCreate: {
    page: '/scouts/create',
    langs: {
      es: '/scouts/nuevo'
    },
    params: ''
  },

  scout: {
    page: '/scouts/:scoutId',
    langs: {
      es: '/scouts/:scoutId'
    },
    params: ''
  },

  candidates: {
    page: '/company/:companyId/offer/:offerId/candidates',
    langs: {
      es: '/empresa/:companyId/oferta/:offerId/candidatos'
    },
    params: ''
  },

  candidate: {
    page: '/company/:companyId/offer/:offerId/candidates/:candidateId',
    langs: {
      es: '/empresa/:companyId/oferta/:offerId/candidatos/:candidateId'
    },
    params: ''
  },

  candidateNotes: {
    page: '/company/:companyId/offer/:offerId/candidates/:candidateId/notes',
    langs: {
      es: '/empresa/:companyId/oferta/:offerId/candidatos/:candidateId/notas'
    },
    params: ''
  },

  offerSearchResult: {
    page: '/company/:companyId/offer/:offerId/search',
    langs: {
      es: '/empresa/:companyId/oferta/:offerId/busqueda'
    },
    params: ''
  },

  offerApplications: {
    page: '/company/:companyId/offer/:offerId/applications',
    langs: {
      es: '/empresa/:companyId/oferta/:offerId/aplicaciones'
    },
    params: ''
  },

  offer: {
    page: '/company/:companyId/offer/:offerId',
    langs: {
      es: '/empresa/:companyId/oferta/:offerId'
    },
    params: ''
  },

  offerConfig: {
    page: '/company/:companyId/offer/:offerId/config',
    langs: {
      es: '/empresa/:companyId/oferta/:offerId/configuracion'
    },
    params: ''
  },

  offerPublicOffer: {
    page: '/company/:companyId/offer/:offerId/public-offer',
    langs: {
      es: '/empresa/:companyId/oferta/:offerId/oferta-publica'
    },
    params: ''
  },

  offerDashboard: {
    page: '/company/:companyId/offer/:offerId/dashboard',
    langs: {
      es: '/empresa/:companyId/oferta/:offerId/dashboard'
    },
    params: ''
  },

  offerTasks: {
    page: '/company/:companyId/offer/:offerId/tasks',
    langs: {
      es: '/empresa/:companyId/oferta/:offerId/tareas'
    },
    params: ''
  },

  offerNotes: {
    page: '/company/:companyId/offer/:offerId/notes',
    langs: {
      es: '/empresa/:companyId/oferta/:offerId/notas'
    },
    params: ''
  },

  offerAuditLog: {
    page: '/company/:companyId/offer/:offerId/audit',
    langs: {
      es: '/empresa/:companyId/oferta/:offerId/auditoria'
    },
    params: ''
  },

  company: {
    page: '/company/:companyId',
    langs: {
      es: '/empresa/:companyId'
    },
    params: ''
  },

  companyDetails: {
    page: '/company/:companyId/details',
    langs: {
      es: '/empresa/:companyId/detalles'
    },
    params: ''
  },

  companyDetailsV2: {
    page: '/company/:companyId/details-v2',
    langs: {
      es: '/empresa/:companyId/detallesV2'
    },
    params: ''
  },

  companyConfig: {
    page: '/company/:companyId/config',
    langs: {
      es: '/empresa/:companyId/configuracion'
    },
    params: ''
  },

  companyUsers: {
    page: '/company/:companyId/users',
    langs: {
      es: '/empresa/:companyId/usuarios'
    },
    params: ''
  },

  companyUserCreate: {
    page: '/company/:companyId/users/create',
    langs: {
      es: '/empresa/:companyId/usuarios/nuevo'
    },
    params: ''
  },

  companyUser: {
    page: '/company/:companyId/users/:userId',
    langs: {
      es: '/empresa/:companyId/usuarios/:userId'
    },
    params: ''
  },

  notFound: {
    page: '/404',
    langs: {
      es: '/404'
    },
    params: ''
  },

  login: {
    page: '/api/auth/login',
    langs: {
      es: '/entra'
    },
    params: ''
  },

  logout: {
    page: '/api/auth/logout',
    langs: {
      es: '/logout'
    },
    params: ''
  }
};
