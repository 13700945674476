import { Logger } from '@utils/log';
import { Component, ErrorInfo, ReactNode } from 'react';
import { ErrorBoundaryProps, ErrorBoundaryState } from './ErrorBoundary.types';
import { ErrorFallback } from './ErrorFallback';

// IMPORTANT:
// Error boundaries work like a JavaScript catch {} block, but for components.
// Only class components can be error boundaries.

// Class.
// -----------------------------------------------------------------------------
export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  // State.
  // ---------------------------------------------------------------------------
  public state: ErrorBoundaryState = {
    hasError: false
  };

  // Life-cycle
  // ---------------------------------------------------------------------------
  static getDerivedStateFromError(): ErrorBoundaryState {
    return {
      hasError: true
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    Logger.error({
      context: 'ErrorBoundary',
      message: `Error captured by ErrorBoundary`,
      error,
      object: errorInfo
    });
  }

  // Handler.
  // ---------------------------------------------------------------------------
  handleOnRetry(): void {
    this.setState({ hasError: false });
  }

  // Render.
  // ---------------------------------------------------------------------------
  render(): ReactNode {
    if (this.state.hasError) {
      return <ErrorFallback onRetry={this.handleOnRetry} />;
    }

    return this.props.children;
  }
}
