import { ENV } from './env-vars';

export const getPipedrivePersonUrl = (pipedriveId: string | number): string =>
  `${ENV.pipedriveBaseUrl}/person/${pipedriveId}`;

export const getPipedriveDealUrl = (pipedriveId: string | number): string =>
  `${ENV.pipedriveBaseUrl}/deal/${pipedriveId}`;

export const isValidPipedriveDealUrl = (url: string): boolean =>
  url.startsWith(`${ENV.pipedriveBaseUrl}/deal/`);

export const getPipedriveCompanyUrl = (pipedriveId: string | number): string =>
  `${ENV.pipedriveBaseUrl}/organization/${pipedriveId}`;

export const isValidPipedriveCompanyUrl = (url: string): boolean =>
  url.startsWith(`${ENV.pipedriveBaseUrl}/organization/`);

export function getPipedriveIdFromPipedriveCompanyUrl(url: string): number {
  if (!url) throw Error('URL is required');

  const split = url.split(`${ENV.pipedriveBaseUrl}/organization/`);

  if (!split || split.length < 2) throw Error('Must have an id');

  return parseInt(split[1].trim(), 10);
}

export function getPipedriveIdFromPipedriveDealUrl(
  url?: string
): number | undefined {
  if (!url) return undefined;

  const split = url.split(`${ENV.pipedriveBaseUrl}/deal/`);

  if (!split || split.length < 2) return undefined;

  return parseInt(split[1].trim(), 10);
}
