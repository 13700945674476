import { Scout } from '@models/scout';
import {
  AdminScoutCreateRequest,
  AdminScoutResponse,
  AdminScoutUpdateRequest
} from '@talent-engine/api-common';
import { ApiServiceOptions, getApiUrl } from '@utils/api';
import { httpClient } from '@utils/http';
import {
  normalizeToScout,
  normalizeToScouts
} from './service.scout.normalizer';

export const ServiceScout = {
  /**
   * Get the list of scouts
   *
   * @param opts the API options
   * @returns the list of scouts
   */
  getAll: async ({ opts }: { opts?: ApiServiceOptions }): Promise<Scout[]> => {
    const url = getApiUrl(`/admin/scouts`, opts?.useAppProxy);

    const res = await httpClient.get<AdminScoutResponse[]>(url, {
      bearer: opts?.token
    });

    return normalizeToScouts(res);
  },

  /**
   * Get the list of scouts by company
   *
   * @param opts the API options
   * @returns the list of scouts
   */
  getAllByCompany: async ({
    opts,
    companyId
  }: {
    companyId: string | number;
    opts?: ApiServiceOptions;
  }): Promise<Scout[]> => {
    const url = getApiUrl(
      `/admin/scouts?companyId=${companyId}`,
      opts?.useAppProxy
    );

    const res = await httpClient.get<AdminScoutResponse[]>(url, {
      bearer: opts?.token
    });

    return normalizeToScouts(res);
  },

  /**
   * Get the scout info for a given scout ID
   *
   * @param scoutId the scout Id
   * @param opts the API options
   * @returns the scout info
   */
  get: async ({
    scoutId,
    opts
  }: {
    scoutId: string | number;
    opts?: ApiServiceOptions;
  }): Promise<Scout> => {
    const scouts = await ServiceScout.getAll({ opts });

    const scout = scouts.find((scout) => scout.id === +scoutId);

    if (!scout) throw new Error('Scout not found');

    return scout;
  },

  /**
   * Create a scout
   *
   * @param data the new scout data
   * @param opts the API options
   * @returns the admin scout info
   */
  create: async ({
    data,
    opts
  }: {
    data: AdminScoutCreateRequest;
    opts?: ApiServiceOptions;
  }): Promise<Scout> => {
    const url = getApiUrl(`/admin/scouts`, opts?.useAppProxy);

    const res = await httpClient.post<
      AdminScoutCreateRequest,
      AdminScoutResponse
    >(url, data, {
      bearer: opts?.token
    });

    return normalizeToScout(res);
  },

  /**
   * Update a scout
   *
   * @param scoutId the scout Id
   * @param data the new scout data
   * @param opts the API options
   * @returns the admin scout info
   */
  update: async ({
    scoutId,
    data,
    opts
  }: {
    scoutId: string;
    data: AdminScoutUpdateRequest;
    opts?: ApiServiceOptions;
  }): Promise<Scout> => {
    const url = getApiUrl(`/admin/scouts/${scoutId}`, opts?.useAppProxy);

    const res = await httpClient.put<
      AdminScoutUpdateRequest,
      AdminScoutResponse
    >(url, data, {
      bearer: opts?.token
    });

    return normalizeToScout(res);
  },

  /**
   * Link the current logged scout to the given company
   *
   * @param companyId the company Id
   * @param opts the API options
   *
   * @returns the admin scout info
   */
  linkToCompany: async ({
    companyId,
    opts
  }: {
    companyId: string | number;
    opts?: ApiServiceOptions;
  }): Promise<Scout> => {
    const meUrl = getApiUrl(`/admin/scouts/me`, opts?.useAppProxy);

    const scoutRes = await httpClient.get<AdminScoutResponse>(meUrl, {
      bearer: opts?.token
    });

    const scout = normalizeToScout(scoutRes);

    const updateUrl = getApiUrl(`/admin/scouts/${scout.id}`, opts?.useAppProxy);

    const res = await httpClient.put<
      AdminScoutUpdateRequest,
      AdminScoutResponse
    >(
      updateUrl,
      {
        companyId: +companyId,
        name: scout.name || '',
        pipedriveId: scout.pipedriveId
      },
      {
        bearer: opts?.token
      }
    );

    return normalizeToScout(res);
  }
};
