import { Icon } from '@asgard/ui-library';
import { faEmptySet } from '@fortawesome/pro-light-svg-icons';
import * as S from './NoContent.styled';
import { NoContentProps } from './NoContent.types';

export const NoContent: React.FC<NoContentProps> = ({
  text,
  actionsSlot,
  size = 'large',
  align = 'center'
}) => {
  return (
    <S.NoContent align={align}>
      <S.Intro align={align}>
        <S.Icon>
          <Icon icon={faEmptySet} size={size === 'large' ? '7xl' : '5xl'} />
        </S.Icon>

        <S.Text size={size}>{text}</S.Text>
      </S.Intro>

      {actionsSlot && <S.Actions>{actionsSlot}</S.Actions>}
    </S.NoContent>
  );
};
