export function timeAgo(input: Date | string, locale = 'es'): string {
  const date = input instanceof Date ? input : new Date(input);
  const formatter = new Intl.RelativeTimeFormat(locale);
  const ranges = [
    ['years', 3600 * 24 * 365],
    ['months', 3600 * 24 * 30],
    ['weeks', 3600 * 24 * 7],
    ['days', 3600 * 24],
    ['hours', 3600],
    ['minutes', 60],
    ['seconds', 1]
  ] as const;
  const secondsElapsed = (date.getTime() - Date.now()) / 1000;

  for (const [rangeType, rangeVal] of ranges) {
    if (rangeVal < Math.abs(secondsElapsed)) {
      const delta = secondsElapsed / rangeVal;
      return formatter.format(Math.round(delta), rangeType);
    }
  }

  return '';
}

export function formatDateTime(input: Date | string, locale = 'es'): string {
  const date = input instanceof Date ? input : new Date(input);
  const formatter = new Intl.DateTimeFormat(locale, {
    dateStyle: 'short',
    timeStyle: 'short'
  });
  return formatter.format(date);
}

export function formatDate(
  input: Date | string,
  locale = 'es',
  format: 'medium' | 'long' | 'short' | 'full' = 'short'
): string {
  const date = input instanceof Date ? input : new Date(input);
  const formatter = new Intl.DateTimeFormat(locale, {
    dateStyle: format
  });
  return formatter.format(date);
}

export function formatDateMonthYear(
  date?: string | Date,
  locale?: string
): string {
  if (!date) {
    return '';
  }

  return new Intl.DateTimeFormat(locale || 'es', {
    year: 'numeric',
    month: 'short'
  }).format(typeof date === 'string' ? new Date(date) : date);
}

export const getCurrentYear = (): number => new Date().getFullYear();
