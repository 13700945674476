import { ENV } from './env-vars';

export type ApiServiceOptions = {
  /**
   * Wether if the request should be proxied through the app server (`<NextJS App>/api`) or not.
   */
  useAppProxy?: boolean;

  /**
   * The bearer token to be used in the request.
   */
  token?: string;
};

/**
 * Get the full URL for a given API path
 *
 * @param path The API path
 * @param useAppProxy wether if the request should be proxied through the app server (`<NextJS App>/api`) or not.
 *
 * @returns the full URL to the given API path
 */
export function getApiUrl(path: string, useAppProxy = true): string {
  const url = `${useAppProxy ? '/api' : ENV.apiUrl}${path}`;

  return url;
}

/**
 *  Get the full URL for a given Asgard API path
 *
 * @param path The API path
 * @returns the full URL to the Asgard API server for the given API path
 */
export function getAsgardApiUrl(path: string): string {
  return `${ENV.asgardApiUrl}${path}`;
}
