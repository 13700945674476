import { IconDefinition, IconName } from '@fortawesome/fontawesome-svg-core';

export const faPipedrive: IconDefinition = {
  // Use a prefix like 'fac' that doesn't conflict with a prefix in the standard Font Awesome styles
  // (So avoid fab, fal, fas, far, fa)
  prefix: 'fas',
  iconName: 'pipedrive' as IconName, // Any name you like
  icon: [
    32, // width
    32, // height
    [], // ligatures
    '', // unicode (if relevant)
    'm20.72,13.48c0,2.8-1.89,4-3.55,4-2.26,0-3.35-2.03-3.35-3.97,0-2.97,1.8-4.03,3.4-4.03,1.95,0,3.49,1.63,3.49,4Zm11.28,2.52c0,8.84-7.16,16-16,16S0,24.84,0,16,7.16,0,16,0s16,7.16,16,16Zm-7.02-2.54c0-4.46-2.72-7.46-6.75-7.46-2.34,0-4,1.23-4.63,2.29-.11-.6-.51-1.97-2.12-1.97h-3.49v3.49h1.43c.26,0,.31.06.31.29v16.4h4.17v-6.34l-.03-1.03c.6,1,2.2,1.8,4.15,1.8,4.09,0,6.95-3.03,6.95-7.46Z'
  ]
};
