import { PaginatedQuery, PaginatedResource } from '@models/paginated-resource';

export const PAGINATION_DEFAULT_PAGE_SIZE = 10;
export const PAGINATION_INITIAL_PAGE = 1;
export const PAGINATION_EMPTY_RESULT: PaginatedResource<void> = {
  data: [],
  page: PAGINATION_INITIAL_PAGE,
  size: PAGINATION_DEFAULT_PAGE_SIZE,
  total: 0
};

export const getPaginationQuery = (args: PaginatedQuery): URLSearchParams => {
  const {
    page = PAGINATION_INITIAL_PAGE,
    pageSize = PAGINATION_DEFAULT_PAGE_SIZE
  } = args;
  const params = new URLSearchParams();

  if (args.page) {
    params.append('page', page.toString());
  }

  if (args.pageSize) {
    params.append('pageSize', pageSize.toString());
  }

  return params;
};
