import { useMutation } from '@asgard/ui-common';
import { HttpError } from '@models/http-error';
import { OfferTask } from '@models/offer-task';
import { ServiceOfferTask } from '@services/offer-task';
import { mutate } from 'swr';
import { USE_SCOUT_TASKS_KEY } from '../useScoutTasks/useScoutTasks.constants';
import { USE_OFFER_TASKS_KEY } from './useOfferTaskUpdate.constants';
import { UseOfferTaskUpdate } from './useOfferTaskUpdate.types';

export const useOfferTaskUpdate: UseOfferTaskUpdate = () => {
  const { trigger: markAsDone } = useMutation<
    {
      taskId: string | number;
      companyId: string | number;
      offerId: string | number;
    },
    OfferTask | void,
    HttpError
  >(
    `${USE_OFFER_TASKS_KEY}`,
    async ({ taskId, companyId, offerId }): Promise<OfferTask | void> => {
      const res = await ServiceOfferTask.update({
        companyId,
        offerId,
        taskId,
        data: {
          status: 'COMPLETED'
        }
      });

      mutate(`${USE_OFFER_TASKS_KEY}/${companyId}/${offerId}`);

      mutate(
        (key) =>
          typeof key === 'string' &&
          key.startsWith(`${USE_SCOUT_TASKS_KEY}/page`)
      );

      return res;
    }
  );

  return {
    markAsDone
  };
};
