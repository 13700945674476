import {
  Avatar,
  ButtonLink,
  Dropdown,
  Icon,
  Stack,
  Tooltip,
  useToast
} from '@asgard/ui-library';
import { Breadcrumbs } from '@components/Breadcrumbs';
import { ConfirmModal } from '@components/ConfirmModal';
import { TabNavigation } from '@components/TabNavigation/TabNavigation';
import {
  faCog,
  faDashboard,
  faEllipsisV,
  faExternalLink,
  faEyes,
  faHome,
  faSignOut,
  faSlidersH,
  faSquarePollHorizontal,
  faUserAstronaut
} from '@fortawesome/pro-solid-svg-icons';
import { useAppVersion } from '@hooks/api/useAppVersion';
import { useI18n } from '@hooks/useI18n';
import { HttpError } from '@models/http-error';
import { ServiceScout } from '@services/scout';
import {
  APP_PAGES,
  getCompaniesPagePath,
  getHomePagePath,
  getManfrediteSearchPagePath,
  getOfferAuditLogPagePath,
  getOfferConfigPagePath,
  getPagePath,
  getScoutsPagePath
} from '@utils/app-pages';
import { getCurrentYear } from '@utils/date';
import { ENV } from '@utils/env-vars';
import { getErrorMessage } from '@utils/errors';
import { Logger } from '@utils/log';
import { isOfferFeat } from '@utils/offer-feats';
import { getPipedriveDealUrl } from '@utils/pipedrive';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback, useState } from 'react';
import * as S from './Page.styles';
import { PageProps } from './Page.types';

export const Page: React.FC<PageProps> = ({
  children,
  user,
  tabs,
  breadcrumbs,
  actionsSlot,
  offerFeats,
  offer,
  companyId,
  hideHeader = false
}) => {
  const { t } = useI18n('common');
  const { locale, push } = useRouter();
  const { showError } = useToast();

  const { version, error, isLoading } = useAppVersion();

  const [isLoginAsClientModalOpened, setIsLoginAsClientModalOpened] =
    useState(false);

  const loginAsClient = useCallback(async () => {
    if (!companyId) return;

    try {
      await ServiceScout.linkToCompany({
        companyId
      });

      setIsLoginAsClientModalOpened(false);

      window.open(`${ENV.talentEngineClientUrl}/oferta/${offer?.id}`, '_blank');
    } catch (error) {
      Logger.error({
        context: 'Page.loginAsClient',
        message: 'Could not login as client',
        error: error as HttpError
      });

      showError(
        t('common.login-as-client-modal.login-as-client_error', {
          error: getErrorMessage(error)
        }),
        t('common.login-as-client-modal.login-as-client_error_title')
      );
    }
  }, [companyId, offer, showError, t]);

  return (
    <S.Container>
      <ConfirmModal
        isOpen={isLoginAsClientModalOpened}
        onClose={() => setIsLoginAsClientModalOpened(false)}
        onConfirm={loginAsClient}
        title={t('common.login-as-client-modal.title')}
        desc={t('common.login-as-client-modal.desc')}
      />

      {!hideHeader && (
        <S.Header>
          <Stack
            gap="xl"
            direction="row"
            alignment="center"
            contentAlign="space-between"
          >
            <Stack gap="m" direction="row" alignment="center">
              <Link href={getHomePagePath(locale)} passHref>
                <a>
                  <S.LogoTE
                    src="/images/talent-engine-backoffice.svg"
                    alt={t('common.page-title')}
                    width="175px"
                  />
                </a>
              </Link>

              {user && (
                <S.Nav>
                  <Stack direction="row" gap="6xl">
                    <Link href={getCompaniesPagePath(locale)} passHref>
                      <a>{t('common:common.nav.clients')}</a>
                    </Link>

                    <Link href={getManfrediteSearchPagePath(locale)} passHref>
                      <a>{t('common:common.nav.manfredites')}</a>
                    </Link>
                  </Stack>
                </S.Nav>
              )}
            </Stack>

            {user && (
              <S.UserNav>
                <Tooltip
                  content={t('common:common.app-admin-section')}
                  direction="left"
                >
                  <Dropdown
                    size="xs"
                    variant="bare"
                    position="right"
                    options={[
                      {
                        asLink: true,
                        icon: faUserAstronaut,
                        label: t('common:common.nav.scouts'),
                        id: 'admin-scouts',
                        href: getScoutsPagePath(locale)
                      },
                      {
                        asLink: true,
                        target: '_blank',
                        icon: faDashboard,
                        label: t('common:common.nav.metrics'),
                        id: 'admin-metrics',
                        href: 'https://metabase.getmanfred.com/collection/39-manfred'
                      }
                    ]}
                    icon={faSlidersH}
                  />
                </Tooltip>

                <Dropdown
                  size="s"
                  variant="bare"
                  position="right"
                  options={[
                    {
                      asLink: false,
                      icon: faHome,
                      label: t('common.my-space'),
                      id: 'home',
                      callback: () => {
                        push(getHomePagePath(locale));
                      }
                    },
                    {
                      asLink: true,
                      icon: faSignOut,
                      href: getPagePath(APP_PAGES.logout, locale),
                      label: t('common.logout'),
                      id: 'logout'
                    }
                  ]}
                  label={
                    <>
                      {user.avatarUrl ? (
                        <Avatar
                          size="s"
                          img={{
                            src: user.avatarUrl,
                            alt: user.name
                          }}
                        />
                      ) : (
                        <Avatar
                          size="s"
                          text={{
                            text: user.initials
                          }}
                        />
                      )}
                    </>
                  }
                />
              </S.UserNav>
            )}
          </Stack>
        </S.Header>
      )}

      <S.Content>
        <Stack direction="column" gap="3xl">
          <Stack direction="column" gap="m">
            {breadcrumbs && breadcrumbs.length > 0 && (
              <Stack className="breadcrumbs" alignment="center" wrap gap="m">
                <Breadcrumbs links={breadcrumbs} />
              </Stack>
            )}
            {!!offerFeats && offerFeats.length > 0 && (
              <S.OfferToolbar>
                <Stack contentAlign="space-between">
                  <Stack alignment="center">
                    {offerFeats?.map((feat, index) => (
                      <React.Fragment key={index}>
                        {isOfferFeat(feat) ? (
                          <>
                            <Stack
                              gap="xs"
                              key={feat.label}
                              alignment="center"
                              className="offer-feat"
                              wrap
                            >
                              <Icon icon={feat.icon} size="l" />
                              <span>{feat.label}</span>
                            </Stack>
                          </>
                        ) : (
                          feat
                        )}
                      </React.Fragment>
                    ))}
                  </Stack>

                  {offer && companyId && (
                    <Stack gap="s" alignment="center">
                      {offer?.pipedriveId && (
                        <Tooltip
                          content={t('common:common.pipedrive-deal-link-title')}
                        >
                          <Link
                            passHref
                            href={getPipedriveDealUrl(offer.pipedriveId)}
                            title={t('common:common.pipedrive-deal-link-title')}
                          >
                            <ButtonLink
                              href="#"
                              target="_blank"
                              schema="neutral"
                              variant="bare"
                              size="2xs"
                              label={t('common:common.view-in-pipedrive')}
                              startIcon={faExternalLink}
                            />
                          </Link>
                        </Tooltip>
                      )}

                      <Dropdown
                        size="xs"
                        variant="bare"
                        position="right"
                        options={[
                          {
                            asLink: false,
                            icon: faEyes,
                            label: t(
                              'common:common.login-as-client-modal.title'
                            ),
                            id: 'login-as-client',
                            callback: () => {
                              setIsLoginAsClientModalOpened(true);
                            }
                          },
                          {
                            asLink: true,
                            icon: faSquarePollHorizontal,
                            label: t('common:common.tabs.offer-audit-log'),
                            id: 'offer-audit-log',
                            href: getOfferAuditLogPagePath(
                              offer.id,
                              companyId,
                              locale
                            )
                          },
                          {
                            asLink: true,
                            icon: faCog,
                            label: t('common:common.tabs.offer-config'),
                            id: 'offer-config',
                            href: getOfferConfigPagePath(
                              offer.id,
                              companyId,
                              locale
                            )
                          }
                        ]}
                        icon={faEllipsisV}
                      />
                    </Stack>
                  )}
                </Stack>
              </S.OfferToolbar>
            )}
          </Stack>

          <Stack direction="column" gap="3xl">
            {actionsSlot && <S.ActionsSlot>{actionsSlot}</S.ActionsSlot>}

            {tabs && tabs.length > 0 && <TabNavigation links={tabs} />}
          </Stack>
        </Stack>

        {children}
      </S.Content>

      <S.Footer>
        <Stack
          direction="column"
          gap="m"
          alignment={!user ? 'center' : 'flex-start'}
        >
          <div>
            © {getCurrentYear()} <strong>Manfred</strong> Tech S.L.U.
          </div>

          {!isLoading && !error && version && (
            <div>
              {t('common.appVersion', { version: version.appVersion })}
              <br />
              {t('common.apiVersion', { version: version.apiVersion })}
            </div>
          )}
        </Stack>
      </S.Footer>
    </S.Container>
  );
};
