import {
  Button,
  Card,
  Checkbox,
  ControlledTable,
  Header,
  Icon,
  Stack,
  Tooltip,
  useToast
} from '@asgard/ui-library';
import { NoContent } from '@components/NoContent';
import { Pager } from '@components/Pager';
import { HtmlTrans } from '@components/shared/HtmlTrans';
import { MarkdownComponent } from '@components/shared/MarkdownComponent';
import { TimeAgo } from '@components/TimeAgo';
import { faCheck, faTasks } from '@fortawesome/pro-solid-svg-icons';
import { useOfferTaskUpdate } from '@hooks/api/useOfferTaskUpdate';
import { useScoutTasks } from '@hooks/api/useScoutTasks';
import { useI18n } from '@hooks/useI18n';
import { ScoutTask } from '@models/scout-task';
import { SCOUT_TASKS_PAGINATION_SIZE } from '@services/scout-task/service.scout-task';
import { getCandidatePagePath, getOfferTasksPagePath } from '@utils/app-pages';
import { getErrorMessage } from '@utils/errors';
import { Logger } from '@utils/log';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import * as S from './ScoutTasksWidget.styles';

export const ScoutTasksWidget: React.FC = () => {
  const { t } = useI18n('home');
  const { locale } = useRouter();

  const { showError, showSuccess } = useToast();

  const [currentMarkAsDoneTaskId, setCurrentMarkAsDoneTaskId] = useState<
    number | null
  >(null);
  const [showCompleted, setShowCompleted] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState<ScoutTask[]>([]);

  const { tasks, isLoading } = useScoutTasks({
    page: currentPage,
    includeCompleted: showCompleted
  });

  const { markAsDone } = useOfferTaskUpdate();

  const onMarkAsDone = useCallback(
    async (id: number, processId: number, companyId: number) => {
      setCurrentMarkAsDoneTaskId(id);

      await markAsDone(
        { taskId: id, offerId: processId, companyId },
        {
          onSuccess: () => {
            showSuccess(
              t('offer-tasks:offer-tasks.list.messages.success-mark-as-done'),
              t(
                'offer-tasks:offer-tasks.list.messages.success-mark-as-done-title'
              )
            );
          },

          onError: (error) => {
            Logger.error({
              context: 'ScoutTasksWidget',
              message: `Could not mark pending task with id ${id} as done`,
              error: error as Error
            });

            showError(
              t('offer-tasks:offer-tasks.list.messages.error-mark-as-done', {
                error: getErrorMessage(error)
              }),
              t(
                'offer-tasks:offer-tasks.list.messages.error-mark-as-done-title'
              )
            );
          },

          finally: () => {
            setCurrentMarkAsDoneTaskId(null);
          }
        }
      );
    },
    [markAsDone, showError, showSuccess, t]
  );

  useEffect(() => {
    if (tasks?.data) {
      setData(tasks.data);
    }
  }, [tasks?.data]);

  useEffect(() => {
    if (
      tasks &&
      currentPage > Math.ceil(tasks.total / SCOUT_TASKS_PAGINATION_SIZE)
    ) {
      setCurrentPage((prev) => prev - 1);
    }
  }, [currentPage, tasks]);

  return (
    <S.Root>
      <Stack alignment="center">
        <Header as="h2" size="3xl">
          <Stack gap="m">
            <Icon icon={faTasks} size="xl" />
            {t('home.scout-dashboard.tasks.title')}
          </Stack>
        </Header>

        <Checkbox
          label={t('home.scout-dashboard.tasks.show-completed')}
          name="scoutTasks_showCompleted"
          checked={showCompleted}
          onChange={() => {
            setShowCompleted((prev) => !prev);
            setCurrentPage(1);
          }}
        />
      </Stack>

      <Stack direction="column" gap="m">
        {!isLoading && data.length === 0 && (
          <Card hideImage>
            <NoContent text={t('home.scout-dashboard.tasks.no-content')} />
          </Card>
        )}

        {data.length > 0 && (
          <ControlledTable<ScoutTask>
            dataRowId="id"
            zebra
            data={data}
            columns={[
              {
                type: 'text',
                label: t('offer-tasks:offer-tasks.list.header.process'),
                className: 'offers-list-th-process',
                sortable: false,
                render: ({
                  processName,
                  processId,
                  companyId,
                  companyName
                }) => (
                  <Stack direction="column" gap="s">
                    <Link
                      href={getOfferTasksPagePath(processId, companyId, locale)}
                      passHref
                    >
                      <a>{processName}</a>
                    </Link>

                    <span>{companyName}</span>
                  </Stack>
                )
              },

              {
                type: 'text',
                label: t('offer-tasks:offer-tasks.list.header.name'),
                className: 'offers-list-th-name',
                sortable: false,
                render: ({
                  type,
                  candidateName,
                  candidateId,
                  content,
                  processId,
                  companyId,
                  createdAtAsIsoString
                }) => (
                  <>
                    <TimeAgo
                      dateIsoString={createdAtAsIsoString}
                      prefix={t('common:common.created-at')}
                    />
                    <Stack alignment="center" gap="s">
                      <strong>
                        <HtmlTrans
                          ns="offer-tasks"
                          i18nKey={`offer-tasks:offer-tasks.list.task-subject.${type}`}
                          values={{ candidateName }}
                          components={
                            candidateId
                              ? {
                                  candidateLink: (
                                    <a
                                      href={getCandidatePagePath(
                                        processId,
                                        companyId,
                                        candidateId,
                                        locale
                                      )}
                                      target="_blank"
                                    />
                                  )
                                }
                              : undefined
                          }
                        />
                      </strong>
                    </Stack>
                    {content && (
                      <S.Desc>
                        <MarkdownComponent content={content} />
                      </S.Desc>
                    )}
                  </>
                )
              },

              {
                type: 'button',
                label: t('offer-tasks:offer-tasks.list.header.actions'),
                className: 'offers-list-th-actions',
                align: 'right',
                render: ({
                  id,
                  status,
                  finishedAtAsIsoString,
                  processId,
                  companyId
                }) => (
                  <>
                    {status === 'PENDING' ? (
                      <Tooltip
                        content={t(
                          'offer-tasks:offer-tasks.list.actions.mark-as-done_title'
                        )}
                      >
                        <Button
                          label={t(
                            'offer-tasks:offer-tasks.list.actions.mark-as-done'
                          )}
                          startIcon={faCheck}
                          size="xs"
                          schema="positive"
                          isLoading={currentMarkAsDoneTaskId === id}
                          onClick={() => onMarkAsDone(id, processId, companyId)}
                        />
                      </Tooltip>
                    ) : (
                      <Stack contentAlign="flex-end">
                        <TimeAgo
                          prefix={t('common:common.finished-at')}
                          dateIsoString={
                            finishedAtAsIsoString || new Date().toISOString()
                          }
                        />
                      </Stack>
                    )}
                  </>
                )
              }
            ]}
          />
        )}

        <Stack contentAlign="flex-end">
          <Pager
            page={currentPage}
            total={tasks?.total || 0}
            pageSize={SCOUT_TASKS_PAGINATION_SIZE}
            onNext={() => {
              setCurrentPage((prev) => prev + 1);
            }}
            onPrev={() => {
              setCurrentPage((prev) => prev - 1);
            }}
          />
        </Stack>
      </Stack>
    </S.Root>
  );
};
