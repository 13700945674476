import { OfferTask } from '@models/offer-task';
import {
  AdminTaskResponse,
  AdminTaskUpdateRequest
} from '@talent-engine/api-common';
import { ApiServiceOptions, getApiUrl } from '@utils/api';
import { httpClient } from '@utils/http';
import {
  normalizeToOfferTask,
  normalizeToOfferTasks
} from './service.offer-task.normalizer';

/**
 * Service to interact with an offer tasks
 */
export const ServiceOfferTask = {
  /**
   * Get all the tasks for a given offer, sorted in descending order
   * by the `createdAtAsIsoString` field
   *
   * @param companyId the company id
   * @param offerId the offer id
   * @param opts the api service options
   *
   * @returns the list of pending tasks
   */
  getAll: async (args: {
    companyId: string | number;
    offerId: string | number;
    opts?: ApiServiceOptions;
  }): Promise<OfferTask[]> => {
    const { companyId, offerId, opts } = args;
    const url = getApiUrl(
      `/admin/company/${companyId}/offer/${offerId}/task`,
      opts?.useAppProxy
    );

    const res = await httpClient.get<AdminTaskResponse[]>(url, {
      bearer: opts?.token
    });

    return normalizeToOfferTasks(res).sort((a, b) =>
      b.createdAtAsIsoString.localeCompare(a.createdAtAsIsoString)
    );
  },

  /**
   * Update a given pending task
   *
   * @param companyId the company id
   * @param offerId the offer id
   * @param taskId the task id
   * @param data the data to update
   * @param opts the api service options
   *
   * @returns the updated pending task
   */
  update: async (args: {
    companyId: string | number;
    offerId: string | number;
    taskId: string | number;
    data: AdminTaskUpdateRequest;
    opts?: ApiServiceOptions;
  }): Promise<OfferTask> => {
    const { companyId, offerId, taskId, data, opts } = args;
    const url = getApiUrl(
      `/admin/company/${companyId}/offer/${offerId}/task/${taskId}`,
      opts?.useAppProxy
    );

    const res = await httpClient.put<AdminTaskUpdateRequest, AdminTaskResponse>(
      url,
      data,
      {
        bearer: opts?.token
      }
    );

    return normalizeToOfferTask(res);
  }
};
