import styled from 'styled-components';

export const Root = styled.div`
  td a {
    font-weight: bold;
  }

  .th-status {
    width: 20%;
  }

  .th-company {
    width: 20%;
  }

  .th-process {
  }

  h2 svg {
    color: ${({ theme }) => theme.palette.text.subtle};
  }
`;
