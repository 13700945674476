import { PaginatedResource } from '@models/paginated-resource';
import { ScoutProcess, ScoutProcessSchema } from '@models/scout-process';
import {
  AdminPaginatedResource,
  AdminScoutProcessResponse
} from '@talent-engine/api-common';
import { Logger } from '@utils/log';

export function normalizeToScoutProcesses(
  res: AdminPaginatedResource<AdminScoutProcessResponse>
): PaginatedResource<ScoutProcess> {
  return {
    data: res.data.map(normalizeToScoutProcess),
    page: res.page,
    size: res.size,
    total: res.total
  };
}

export function normalizeToScoutProcess(
  res: AdminScoutProcessResponse
): ScoutProcess {
  const model: ScoutProcess = {
    companyId: res.companyId,
    processId: res.processId,
    companyName: res.companyName,
    processName: res.processName,
    status: res.status
  };

  const result = ScoutProcessSchema.safeParse(model);

  if (!result.success) {
    Logger.error({
      context: 'normalizeToScoutProcess',
      message: `Could not parse scout process with process id: ${model.processId} and company id: ${model.companyId}`,
      error: result.error
    });

    return model;
  }

  return result.data;
}
