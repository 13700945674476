import styled, { css } from 'styled-components';
import { NoContentProps } from './NoContent.types';

export const NoContent = styled.div<{ align: NoContentProps['align'] }>`
  text-align: ${({ align }) => (align === 'left' ? 'left' : 'center')};
  font-size: 2rem;
  padding: ${({ align }) => (align === 'left' ? '0' : '4rem 0')};

  ${({ align }) =>
    align === 'center' &&
    css`
      max-width: 80%;
      margin: 0 auto;
    `}

  ${({ align }) => align === 'left' && css``}
`;

export const Intro = styled.div<{ align: NoContentProps['align'] }>`
  ${({ align }) =>
    align === 'center' &&
    css`
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: center;
    `}

  ${({ align }) =>
    align === 'left' &&
    css`
      display: flex;
      gap: 1rem;
      flex-direction: row;
      align-items: center;
    `}
`;

export const Icon = styled.div`
  svg {
    color: #ccc;
  }
`;

export const Text = styled.div<{
  size: NoContentProps['size'];
}>`
  font-size: ${({ size = 'large' }) => (size === 'large' ? '2rem' : '1.5rem')};
  font-weight: 900;
  color: ${({ theme }) => theme.palette.text.subtle};
`;

export const Actions = styled.div`
  font-size: 1.25rem;
  color: ${({ theme }) => theme.palette.text.subtle};
`;
