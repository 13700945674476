import { AdminLanguageLevelTypeValues } from '@talent-engine/api-common';
import { z } from 'zod';

export const LanguageWithLevelSchema = z.object({
  id: z.number(),
  name: z.string(),
  level: z.enum(AdminLanguageLevelTypeValues)
});

export type LanguageWithLevel = z.infer<typeof LanguageWithLevelSchema>;
